.top-container {
	max-width: 1200px; 
	margin: 0 auto !important; 
	float: none !important; 
}

.content-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.extra-tools-title {
	padding-top: 20px;
	font-size: 160%;
	font-weight: 600;
}

.extra-tools-container {
	padding: 10px;
}

.extra-tools {
	padding: 2px;
}

.extra-tools-inner {
	padding-left: 20px;
}

.teams span {
	vertical-align: middle;
}

.gamesummary-team-name {
	font-size: 160%;
	font-weight: 700;
	padding-bottom: 10px;
	padding-top: 20px;
}

.gamesummary-game-inner-container {
	padding: 20px;
}

.gamesummary-game-container {
	padding: 10px;
}

.gamesummary-title-box {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
	padding-bottom: 4px;
}

.gamesummary-title-sport {
	font-size: 120%;
	font-weight: 600;
	font-style: italic;
	color: #777;
	padding-bottom: 10px;
}

.gamesummary-title-teams {
	font-size: 120%;
	font-weight: 600;
}

.gamesummary-time-box {
	font-size: 110%;
	font-weight: 400;
	font-style: italic;
}

.gamesummary-weather-box {
	font-size: 110%;
	font-weight: 400;
	padding-top: 5px;
	font-style: italic;
}

.gamesummary-series-stats-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
	padding-top: 14px;
}

.gamesummary-series-stat-container {
	display: flex;
	flex-direction: column;
	padding: 10px;
	align-items: center;
	justify-content: center;
	background: black;
	aspect-ratio: 2 / 1;
	height: 74px;
}

.gamesummary-series-stat-line {
	color: white;
	font-weight: 600;
}

.gamesummary-series-stat-line-highlight {
	color: yellow;
}